<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>


    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem"
                placeholder="Search..."
                @input="filterTable"
              />


              <b-button
                v-if="checkPermission($route.name, 'Delete')"
                variant="danger"
                class="mr-1 mobile-margin-bottom"
                @click="changeMultipleStatus('archive')"
              >
                <span class="text-nowrap">Delete</span>
              </b-button>

              <b-button
                v-if="checkPermission($route.name, 'Change Status')"
                variant="success"
                class="mr-1 mobile-margin-bottom"
                @click="changeMultipleStatus('active')"
              >
                <span class="text-nowrap">Activate</span>
              </b-button>

              <b-button
                v-if="checkPermission($route.name, 'Change Status')"
                variant="primary"
                class="mr-1 mobile-margin-bottom"
                @click="changeMultipleStatus('inactive')"
              >
                <span class="text-nowrap">Inactivate</span>
              </b-button>

              <b-button
                v-if="checkPermission($route.name, 'Add Project Site')"
                variant="warning"
                class="mobile-margin-bottom"
                :to="{name:'add-job-sites'}"
              >
                <span class="text-nowrap">Add Project Site</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative mobile_tab_min_width_table new_layout_tbl flex_1000"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall"/>
        </template>

        <template #cell(info)="items">             
            <feather-icon
              :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              @click="items.toggleDetails"
            />
        </template>

        <template #cell(checkbox)="items">
           
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning" />
         
        </template>

        <!-- Column: User -->
        <template #cell(site_name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site_name | capitalize}}</span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(prefix)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.prefix}}</span>
         
        </template>
        <template #cell(country)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.country | capitalize}}</span>
         
        </template>

        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
              @click="items.toggleDetails"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span @click="items.toggleDetails">{{ items.item.created_at | dateTime}}</span>
         
        </template>

        <template #row-details="items">
            <div class="p-1 px-2 fbDetail mb-1">
                <b-row>

                  <b-col cols="12" xl="12" >
                      <table class="mt-2 mt-xl-0 w-100">
                          <tr class="mb-1">
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Address</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.address}}</span>
                              </td>
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Country</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.country}}</span>
                              </td>
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Managing Agent Name</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.managing_agent_name | capitalize}}</span>
                              </td>
                          </tr>

                          <tr class="mb-1">

                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">QI Star/Number Count</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.qi_max}}</span>
                              </td>
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Group Filter</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.group_filter | capitalize}}</span>
                              </td>
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Remarks</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.remarks | capitalize}}</span>
                              </td>
                          </tr>
                          <tr class="mb-1">

                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Allow Attendance</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.allow_attendance | capitalize}}</span>
                              </td>
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Attendance Mode</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.attendance_mode == 'gps'? 'GPS': 'Camera'}}</span>
                              </td>
                              <th class="pb-50" style="width: 10%">
                                <div class="d-flex align-items-center">
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">Restart Failed Tasks</h6>
                                  </div>
                                </div>
                              </th>

                              <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                  <span class="wordBreak">{{items.item.restart_failed_task | capitalize}}</span>
                              </td>
                          </tr>
                          
                          <tr class="mb-1">
                            <th class="pb-50" style="width: 100%" colspan="4">
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-secondary"
                                @click="items.toggleDetails"
                                size="sm"
                                class="ml-1"
                              >
                                Hide Detail
                              </b-button>
                              
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-secondary"
                                @click="viewDetails(items.item)"
                                size="sm"
                                class="ml-1"
                              >
                                View More
                              </b-button>
                            </th>
                          </tr>
                      </table>
                  </b-col>

                </b-row>
            </div>

        </template>

        <template #cell(actions)="items">

          <!-- <b-button
            v-if="checkPermission($route.name, 'View')"
            size="sm"
            variant="warning"
            class="mr-1"
            :to="{ name: 'view-site', params: { id: items.item._id } }"
          >
            View
          </b-button> -->

          <b-button
            v-if="checkPermission($route.name, 'View Location')"
            size="sm"
            variant="warning"
            class="mr-1"
            :to="{ name: 'site-locations', params: { site_id: items.item._id } }"
          >
            View Location
          </b-button>

          <!-- <b-dropdown
            v-if="checkPermission($route.name, 'Edit') || checkPermission($route.name, 'Change Status') || checkPermission($route.name, 'Delete')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            class="common_btn_padding"
          > -->

            <!-- <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->

            <!-- <b-dropdown-item :to="{ name: 'site-locations', params: { site_id: items.item._id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Locations</span>
            </b-dropdown-item> -->


            <!-- <b-dropdown-item v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'edit-job-sites', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Delete')" @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->

            

            
          <!-- </b-dropdown>  -->

          <b-link v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'edit-job-sites', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>
           <b-link v-if="checkPermission($route.name, 'Delete')" @click="deleteItem(items.item._id)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


    <b-modal
        id="pass"
        ref="pass"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Verify Password"
        @ok="verifyPassword"
        no-close-on-backdrop
      >

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-form>
          <b-row>
            <b-col md="12">
              <p><span class="text-danger">Warning: </span>All users with single site access will be deleted and this action cannot be reversed.</p>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label="Please enter your password below for confirmation."
                  class="required"
                >
                  <b-form-input
                    placeholder=""
                    v-model="password"
                  />
              </b-form-group>
            </b-col>
          </b-row>      
        </b-form>
      </b-modal>
    
      <b-modal
        id="site-data"
        ref="site-data"
        size="lg"
        centered
        title="Project Site Details"
        hide-footer
      >

      <div v-if="siteData != null">

        <div>
          
          <b-row class="mt-2">
            <b-col
              cols="12"
              xl="6">
              
              <table class="mt-2 mt-xl-0 w-100">
                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Project Site 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.site_name |capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Address 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.address}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Country 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.country}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          QI Star/Number Count  
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.qi_max}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Managing Agent Name 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.managing_agent_name | capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1" >
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Group Filter 
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.group_filter | capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Remarks 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.remarks | capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Allow Attendance 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.allow_attendance | capitalize}}</p>
                  </td>
                </tr>
                <tr class="mb-1" v-if="siteData.allow_attendance == 'yes'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Attendance Mode 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.attendance_mode == 'gps'? 'GPS': 'Camera'}}</p>
                  </td>
                </tr>

                <tr class="mb-1" v-if="siteData.allow_attendance == 'yes'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Enable Selfie 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.enable_selfie | capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1" v-if="siteData.attendance_mode == 'gps'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Radius (in meter)
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.radius}}</p>
                  </td>
                </tr>

                <tr class="mb-1" v-if="siteData.attendance_mode == 'camera'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Show Timestamp on Image
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.timestamp_on_image | capitalize}}</p>
                  </td>
                </tr>
                
                <tr class="mb-1" v-if="siteData.allow_attendance == 'yes'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Grace Period
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.grace_period + ' min'}}</p>
                  </td>
                </tr>

                <tr class="mb-1" v-if="siteData.allow_attendance == 'yes'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Allow NFC Mode
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.nfc_mode | capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Restart Failed Tasks
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.restart_failed_task | capitalize}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Start Expired Tasks
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.start_expired_task | capitalize}}</p>
                  </td>
                </tr>
                <tr class="mb-1">
                    <th class="pb-50">
                      <div class="d-flex align-items-center mr-2">
                      
                        <div class="ml-1">
                          <h6 class="mb-1">
                            Additional info on Defect
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td class="pb-50">
                      <p class="wordBreak">{{ siteData.additional_info_defect | capitalize }}</p>
                    </td>
                  </tr>
                
              </table>

            </b-col>

            <b-col
              cols="12"
              xl="6">
              
              <table class="mt-2 mt-xl-0 w-100">
                
                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Prefix
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.prefix}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Postal Code
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.postal_code}}</p>
                  </td>
                </tr>
                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          QI Rating
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.qi_type}}</p>
                  </td>
                </tr>
                
                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Department
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.department}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          KPI Contract
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.kpi_contract | capitalize}}</p>
                  </td>
                </tr>

                
                <tr class="mb-1" >
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Date Format 
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{formatType(siteData.pdf_date_format)}}</p>
                  </td>
                </tr>

                <tr class="mb-1" >
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Time Format 
                        </h6>
                      
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.pdf_time_format == 'hh:mm A' ? '01:00 PM' : '13:00'}}</p>
                  </td>
                </tr>
                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          No Photo Reporting 
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.no_photo_reporting | capitalize}}</p>
                  </td>
                </tr>
                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Task Status Update Delay
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.task_status_delay | capitalize}}</p>
                  </td>
                </tr>
                <tr class="mb-1" v-if="siteData.task_status_delay == 'yes'">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          No of Delay Minute
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.task_status_delay_min}}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                      
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Contract Type
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{siteData.site_type == 'hit_count' ? 'Headcount Based' : 'Performance Based'}}</p>
                  </td>
                </tr>

                
                <tr class="mb-1">
                    <th class="pb-50">
                      <div class="d-flex align-items-center mr-2">
                      
                        <div class="ml-1">
                          <h6 class="mb-1">
                            Allow Inventory
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td class="pb-50">
                      <p class="wordBreak">{{ siteData.inventory | capitalize }}</p>
                    </td>
                  </tr>

                  <tr class="mb-1">
                      <th class="pb-50">
                        <div class="d-flex align-items-center mr-2">
                      
                          <div class="ml-1">
                            <h6 class="mb-1">
                              Multiple Delivery
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td class="pb-50">
                        <p class="wordBreak">{{ siteData.multiple_delivery | capitalize }}</p>
                      </td>
                    </tr>

                    <tr class="mb-1">
                      <th class="pb-50">
                        <div class="d-flex align-items-center mr-2">
                    
                          <div class="ml-1">
                            <h6 class="mb-1">
                              Additional Information
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td class="pb-50">
                        <p class="wordBreak">{{ siteData.additional_information | capitalize }}</p>
                      </td>
                    </tr>

                
              </table>

            </b-col>
          </b-row>
          <hr>


          <b-row class="mt-2 mb-2">
                <b-col md="12">
                    <gmap-map
                        ref="googleMap"
                        :center="mapStartLocation"
                        :zoom="zoom"
                        :options="{scrollwheel: false}"

                        style="width:100%;  height: 400px; overflow: auto;border: 2px solid black;border-radius: 4px"
                      >
                        
                          <!-- <gmap-marker
                            
                            class="markerCricle"
                            :position="mapStartLocation"
                          ></gmap-marker> -->

                          <gmap-polygon 
                          :paths="paths" 
                          :editable="false" 
                          :draggable="false"
                          >
                          </gmap-polygon>

                    </gmap-map>

                </b-col>
          </b-row>


          <b-row>
          
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Shift Details
              </h4>
              
            </b-col>
          </b-row>

          <b-table sticky-header
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.shifts"
            
            :fields="shiftColumns"
            primary-key="id" 
            >

            <template #cell(name)="items">
              
              <span>{{ items.item.shift }}</span>
            </template>
            <template #cell(start_day)="items">
              
              <span>{{ items.item.start_day}}</span>
            </template>

            <template #cell(end_day)="items">
              
              <span>{{ items.item.end_day }}</span>
            </template>

            <template #cell(start_time)="items">
              
              <!-- <span>{{ items.item.start_time | time}}</span> -->
              <span>{{ sitetime(items.item.start_time,siteData.pdf_time_format)}}</span>
            </template>

            <template #cell(end_time)="items">
              
              <!-- <span>{{ items.item.end_time |time }}</span> -->
              <span>{{ sitetime(items.item.end_time,siteData.pdf_time_format)}}</span>
            </template>

            <template #cell(holiday)="items">
              
              <span>{{ items.item.holiday | capitalize }}</span>
            </template>

            <template #cell(crew)="items">
              
              <span>{{ items.item.crew ? items.item.crew : '0'}}</span>
            </template>

            <template #cell(supervisor)="items">
              
              <span>{{ items.item.supervisor ? items.item.supervisor : '0' }}</span>
            </template>

          </b-table>


          <hr>
          <b-row>
          
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Contract Period Details
              </h4>
              
            </b-col>
          </b-row>

          <b-table sticky-header
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.contract_periods"
            
            :fields="contractColumns"
            primary-key="id" 
            >

            <template #cell(start_date)="items">
              
              <span>{{ sitedateRangePicker(items.item.start_date,siteData.pdf_date_format,'')}}</span>
            </template>

            <template #cell(end_date)="items">
              
              <!-- <span>{{ items.item.end_date |dateRangePicker}}</span> -->
              <span>{{ sitedateRangePicker(items.item.end_date,siteData.pdf_date_format,'')}}</span>
            </template>

            

          </b-table>

          <hr>

          <b-row>
          
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Work Schedule Details
              </h4>
              
            </b-col>
          </b-row>

          <b-table sticky-header
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.work_schedules"
            
            :fields="workScheduleColumns"
            primary-key="id" 
            >

            <template #cell(startTime)="items">
              <span>{{sitetime(items.item.startTime,siteData.pdf_time_format)}}</span>
            </template>

            <template #cell(endTime)="items">
              <span>{{sitetime(items.item.endTime,siteData.pdf_time_format)}}</span>
            </template>

            

          </b-table>
          <hr>
          <b-row>
          
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Work Days Details
              </h4>
              
            </b-col>
          </b-row>

          <b-table sticky-header
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.work_days"
            
            :fields="workDayColumns"
            primary-key="id" 
            >

            <template #cell(value)="items">
              
              <span>{{ items.item.value}}</span>
            </template>

          </b-table>

          <b-row v-if="siteData.kpi_contract == 'yes'">
          
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                KPI Minutes
              </h4>
              
            </b-col>
          </b-row>

          <b-table sticky-header
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.minutes"
            v-if="siteData.kpi_contract == 'yes'"
            :fields="kpiMinuteColumns"
            primary-key="id" 
            >

            <template #cell(value)="items">
              
              <span>{{ items.item.value}}</span>
            </template>

          </b-table>
          
          <hr v-if="siteData.show_custom_message == 'yes'">
          <b-row v-if="siteData.show_custom_message == 'yes'">
          
            <b-col cols="12" class="mb-2 mt-2">
              <h4 class="mb-0">
                QR Code Configuration
              </h4>
            </b-col>
          </b-row>

          <b-table sticky-header v-if="siteData.show_custom_message == 'yes'"
            ref="refRangeTable"
            class="position-relative"
            :items="[siteData.message]"
            :fields="messageColumns"
            primary-key="id" 
          >
            <template #cell(message)="items">            
              <span>{{ items.item}}</span>
            </template>
          </b-table>

          <hr v-if="siteData.site_type == 'performance_basis'">
          <b-row v-if="siteData.site_type == 'performance_basis'">
          
            <b-col cols="12" class="mb-2 mt-2">
              <h4 class="mb-0">
                Severity Level
              </h4>
            </b-col>
          </b-row>

          <b-table sticky-header v-if="siteData.site_type == 'performance_basis'"
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.severality_levels"
            :fields="severality_fields"
            primary-key="id" 
          >
            <template #cell(name)="items">            
              <span>{{ items.item.name}}</span>
            </template>

            <template #cell(minute)="items">            
              <span>{{ items.item.minute}}</span>
            </template>
          </b-table>

          <hr>
          <b-row>
          
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Notifications
              </h4>
              
            </b-col>
          </b-row>

          <b-table sticky-header
            ref="refRangeTable"
            class="position-relative"
            :items="siteData.notifications"
            :fields="notificationColumns"
          >

            <template #cell(text)="items">
              
              <span>{{ items.item.text |capitalize}}</span>
            </template>

            <template #cell(value)="items">
              
              <span>{{ getRoles(items.item.value)}}</span>
            </template>

            

          </b-table>

          <hr>
          <div v-if="siteData.contract_sum != '' &&  siteData.contract_sum != null">

          <b-row >
            <b-col
            cols="4"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Contract Sum 
              </h4>
              
            </b-col>
            <b-col
            cols="3"
            class="mb-2 mt-2"
            >
              <h5 class="mb-0">
                SGD {{mask}} 
              </h5>
              
            </b-col>
            <b-col
            cols="2"
            class="mb-2 mt-1"
            >
              <b-button
              size="sm"
              variant="warning"
              class="mr-1"
              @click="updateDisable()"
              v-if="pwd_verified == 'no'"
            >
              View
            </b-button>
              
            </b-col>
          </b-row>

          <hr>
          </div>


          

          
          <b-row v-if="siteData.use_logo == 'yes'">

            <b-col
              cols="6"
              class="mb-2 mt-2 margin_top_zero_mobile"
            > 
                  <b-col
                  cols=""
                  class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                  >
                    <h4 class="mb-0">
                      Logo
                    </h4>
                    
                  </b-col>
              

              
                  <b-col
                    
                    class="mobile_pdng_lft_rgt_zero"
                  > 

                      <b-media class="mb-2 mt-2 inline_media_mobile"  v-viewer>
                        <template #aside>
                          <b-avatar
                            ref="supervisorS"
                            :src="siteData.logo"
                            variant="light-primary"
                            size="200px"
                            rounded
                          />
                        </template>

                        <viewer :options="{title: false}"></viewer>

                        
                      </b-media>

                      
                      
                    </b-col>
              
            </b-col>

            <b-col
              cols="6"
              class="mb-2 mt-2 margin_top_zero_mobile"
            > 
              
                
                  <b-col
                  cols=""
                  class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                  >
                    <h4 class="mb-0">
                      App Logo
                    </h4>
                    
                  </b-col>
              

              
                  <b-col
                  class="mobile_pdng_lft_rgt_zero"                  
                    
                  > 
                
                      <b-media class="mb-2 mt-2 inline_media_mobile" v-viewer>
                        <template #aside>
                          <b-avatar
                            ref="clientS"
                            :src="siteData.app_logo"
                            variant="light-primary"
                            size="200px"
                            rounded
                          />
                        </template>

                        <viewer :options="{title: false}"></viewer>

                        
                      </b-media>

                      
                      
                    </b-col>
              
            </b-col>
            
          </b-row>

        </div>


      </div>

      </b-modal>



  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BFormText,BFormGroup,BForm,BFormCheckbox,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
var moment = require('moment-timezone');

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormText,
    BFormGroup,
    BBreadcrumb
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
       tableColumns: [
            { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '3%'}},
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '2%' } },
            { key: 'site_name', label: 'Project Site', sortable: true , thStyle:  {width: '23%'}},
            { key: 'prefix', label: 'Prefix', sortable: true , thStyle:  {width: '12%'}},
            /*{ key: 'country', label: 'Country', sortable: true , thStyle:  {width: '15%'}},*/
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '10%'}},
            { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '35%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        showDismissibleAlert:false,
        error_message:null,
        password:'',
        id:'',
        selectAll:false,
        selected:[],
        delType:'',
        delStatus:'',
        siteData: null,

        // view details vars
        doc_icon: require('@/assets/images/doc.png'),
        mask:'',
        pwd_verified:'no',
        shiftColumns : [
            { key: 'name', label: 'Name', sortable: false , thStyle:  {width: '20%'}},
            { key: 'start_day', label: 'Start Day', sortable: false , thStyle:  {width: '15%'}}, 
            { key: 'end_day', label: 'End Day', sortable: false , thStyle:  {width: '15%'}}, 
            { key: 'start_time', label: 'Start Time', sortable: false , thStyle:  {width: '10%'}}, 
            { key: 'end_time', label: 'End Time', sortable: false , thStyle:  {width: '10%'}},
            { key: 'holiday', label: 'Include Holiday', sortable: false , thStyle:  {width: '10%'}},  
            { key: 'crew', label: 'Crew', sortable: false , thStyle:  {width: '10%'}},
            { key: 'supervisor', label: 'Supervisor', sortable: false , thStyle:  {width: '10%'}},
        ],
        contractColumns : [
            { key: 'start_date', label: 'Start Date', sortable: false },
            { key: 'end_date', label: 'End Date', sortable: false }, 
        ],
        notificationColumns : [
            { key: 'text', label: 'Notify On', sortable: false , thStyle:  {width: '60%'}},
            { key: 'value', label: 'User', sortable: false , thStyle:  {width: '40%'}}, 
        ],
        custom_forms : [
            { key: 'title', label: 'title', sortable: false , thStyle:  {width: '50%'}},
            { key: 'validateLevelOne', label: 'Level 1', sortable: false , thStyle:  {width: '25%'}},
            { key: 'validateLevelTwo', label: 'Level 2', sortable: false , thStyle:  {width: '25%'}}
        ],

        workScheduleColumns : [

            { key: 'startTime', label: 'Start Time', sortable: false , thStyle:  {width: '50%'}},
            { key: 'endTime', label: 'End Time', sortable: false , thStyle:  {width: '50%'}}
        ],

        workDayColumns : [

            { key: 'value', label: 'No. of working Days', sortable: false , thStyle:  {width: '100%'}},
            
        ],

        kpiMinuteColumns : [

            { key: 'value', label: 'No. of Minutes', sortable: false , thStyle:  {width: '100%'}},
            
        ],
        
        messageColumns : [
            { key: 'message', label: 'Message', sortable: false , thStyle:  {width: '100%'}},          
        ],

        severality_fields : [
            { key: 'name', label: 'Level', sortable: false , thStyle:  {width: '50%'}},  
            { key: 'minute', label: 'No. of Minutes', sortable: false , thStyle:  {width: '50%'}},  
        ],

        mapStartLocation: { lat: 1.3521, lng: 103.8198 },
        zoom:17,
        paths:[],
    }
  },
  methods : {
    formatType(format) {
      return moment().tz('Asia/Singapore').format(format);
    },
    viewDetails(item){
        this.siteData = null;
        this.siteData = item;
        if(item.contract_sum != '' && item.contract_sum.length > 0){
          this.mask = item.contract_sum.replace(/\d/g,"X");
        }
        if (item.geofence.length > 0) {
          this.paths = [];
          this.paths.push(item.geofence);
        }
        this.mapStartLocation.lat = item.lat;
        this.mapStartLocation.lng = item.long;
        this.$refs['site-data'].show();
    },
    getRoles(value){
      var arr = [];
      value.forEach(item => { arr.push(item.name);})
      if (arr.length > 0) {
        return arr.join(', ');
      }else{
        return 'Off';
      }
    },
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                   },
                   api: '/api/site-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Project Site Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Project Site Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-site-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){

      this.delType = '';
      this.delStatus = '';

      //var msg = 'Are you sure want to delete this record?';
      var msg = '<p style="color:red"><b>Note: Please be warned that deleting this project site will result in the deletion of all tagged users (single site access), and this action cannot be reversed</b></p><p>Are you sure you want to delete selected record?</p>';

      Swal.fire({
            title: 'Please Confirm',
            html: msg,
            icon: 'warning',
            position: 'top-center',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        .then(result => {
          if (result.value) {
              
              this.showDismissibleAlert = false;
              this.password = ''; 
              this.id = id;

              this.$refs['pass'].show();
          }
      })
      .catch(err => {
          
      })
    },

    verifyPassword(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
        data:{
              user_id: this.$store.getters.currentUser._id,
              password:this.password
            },
        api:"/api/verify-password",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
              } else {
                  
                  console.log(this.delType);

                  if (this.delType == '') {

                    this.removeSite();
                  }else{
                    this.removeMultipleSite();
                  }
                  

                  this.$refs['pass'].hide();
                
              }
          });
    },
    removeSite(){
        var message='Project Site Deleted Successfully.';
        
        return this.$store.dispatch(POST_API, {
            data:{
                  id: this.id,
                  status:'archive'
                },
            api:"/api/change-site-status",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                  } else {
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: message,
                      showConfirmButton: false,
                      timer: 1500
                    })
                    
                    this.$refs.refUserListTable.refresh();
                  }
              });
    },
    showAlert(){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500
      })
    },
    selectall(e){

      this.selected = [];
      
      if (e) {
        var selected = [];

        this.items.forEach(function(item){
            selected.push(item._id);
        })

        this.selected = selected;

      }
    },
    changeMultipleStatus(status){
      
      this.password = '';

      this.delType = 'multiple';
      this.delStatus = status;


      if (this.selected.length > 0) {
       var msg = '';
       var msg2 = '';

       if (status == 'archive') {
          //msg = 'Are you sure you want to delete selected record?';
          msg = '<p style="color:red"><b>Note: Please be warned that deleting this project site will result in the deletion of all tagged users (single site access), and this action cannot be reversed</b></p><p>Are you sure you want to delete selected record?</p>';
          msg2 = 'Records Deleted Successfully.';
       }if (status == 'active') {
          msg = 'Are you sure you want to Activate selected record?';
          msg2 = 'Records Activated Successfully.';
       }if (status == 'inactive') {

          msg = 'Are you sure you want to inactive selected record?';
          msg2 = 'Records Inactivated Successfully.';
       }


       Swal.fire({
            title: 'Please Confirm',
            html: msg,
            icon: 'warning',
            position: 'top-center',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        .then(result => {
          if (result.value) {

              if (status == 'archive') {

                this.showDismissibleAlert = false;
                this.$refs['pass'].show();

              }else{
                this.removeMultipleSite();
              } 
          }
        })
        .catch(err => {
            // An error occurred
        })  
  
      }else{
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Please select project sites.',
            showConfirmButton: false,
            timer: 1500
          })
      }
    },
    removeMultipleSite(){
       var msg2 = '';
       if (this.delStatus == 'archive') {
          //msg = 'Are you sure you want to delete selected record?';
          msg2 = 'Records Deleted Successfully.';
       }
       if (this.delStatus == 'active') {
          //msg = 'Are you sure you want to Activate selected record?';
          msg2 = 'Records Activated Successfully.';
       }
       if (this.delStatus == 'inactive') {

          //msg = 'Are you sure you want to inactive selected record?';
          msg2 = 'Records Inactivated Successfully.';
       }

      return this.$store.dispatch(POST_API, {
          data:{
                id: this.selected,
                status:this.delStatus
              },
          api:"/api/change-multiple-site-status",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                  
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: msg2,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  
                  this.selected = [];
                  this.selectAll = false;
        
                  this.$refs.refUserListTable.refresh();
                }
            });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to: null, // hyperlink
        text: 'Project Sites' // crumb text
      },
      {
        to: null, // hyperlink
        text: 'Sites', // crumb text
        active: true
      }];
      return item;
    }
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
